<template>
    <div class="feedbackWarp">
        <div class="lintgray"></div>
        <div class="title">
            <p class="lineTitle">意见反馈</p>
        </div>

        <div class="feedbackInput">
            <van-field
                    v-model="feedbackForm.suggest"
                    autosize
                    type="textarea"
                    maxlength="200"
                    placeholder="请详述您的问题，以便我们为您提供很好的帮助"
                    show-word-limit

            />
        </div>
        <div class="lintgray"></div>
        <div class="imgUpload" >
            <div class="imgTitleDiv">
                <p class="lineTitle">图片上传</p>
            </div>
            <div class="imgDiv">
                <van-uploader v-model="imgArr" accept="image" :max-count="2"  :after-read="afterRead" :before-delete="deletePic"/>
            </div>

        </div>
        <div class="publicBtnDiv">
            <van-button class="publicBtnActive"   round block @click="commitImgs">提 交</van-button>
        </div>

    </div>

</template>

<script>
    import {Toast} from "vant";
    import common from '@/utils/common'
    import JsZip from "jszip";
    import {insertUserSugges, uploadZipAndAlreadyImg} from "../../utils/api";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "feedbackInput",
        data(){
            return{

                imgArr:[],
                uploadZipImgList:[],
                feedbackForm:{
                    suggest:'' ,
                    imgPath:''
                }
            }
        },
        beforeRouteLeave (to, from, next) {
            if (to.name == "feedbackInput") {
            }else{
                this.$store.dispatch('keepAliveState/removeKeepAlive', 'expressInput')
            }
            next()
        },
        mounted() {
            if (!common.getItem('wtToken')){
                this.$router.push({
                    path:'/main/login',
                    query:{
                        back001:'1'
                    }
                })
            }
            uwStatisticAction('/feedbackInput','意见反馈')
        },
        methods:{
            afterRead(file, detail) {
                // 大于15M
                if(file.file.size > 15 * 1024 * 1024){
                    Toast('图片太大，请上传小于15M的图片')
                    return
                }
                if(!common.imageVerify(file.file.type)){
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if(file.file.size < 100 * 1024){
                    console.log("小图片")
                    let namearr = file.file.name.split('.')
                    let name = '.'+namearr[namearr.length-1]
                    _this.uploadZipImgList.push({data:file.content,name:name})
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content= file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.'+namearr[namearr.length-1]
                    img.src = content
                    img.onload = function() {
                        console.log("压缩图片")
                        common.compress(img, (dataURL)=>{
                            _this.uploadZipImgList.push({data:dataURL,name:name})
                        })
                    }
                }
            },
            deletePic(file, pic) {
                this.imgArr.splice(pic.index,1)
                this.uploadZipImgList.splice(pic.index,1)
            },
            commitImgs(){
                if(this.feedbackForm.suggest.length<10){
                    Toast('请输入10-200字的反馈意见')
                    return
                }
                if(this.uploadZipImgList.length < 1 ){
                    Toast('请上传1-2张反馈问题的截图和照片')
                    return
                }
                let self = this


                var zip = new JsZip();
                var img = zip.folder("images");
                for (var i=0;i<this.uploadZipImgList.length;i++){
                    img.file("suggestPic_"+ i+this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",")+1), {base64: true});
                }
                zip.generateAsync({type:"blob"}).then(function(content) {
                    uploadZipAndAlreadyImg(content).then(res=>{
                        if (res.code == 200){
                            if (res.data.businessCode && res.data.businessCode === 200) {
                                self.feedbackForm.imgPath =JSON.stringify(res.data.suggestPic)
                                self.commitFeedback()
                            }
                        }
                    }).catch(()=>{
                    })

                },e=>{
                    this.$toast('上传图片异常')

                });

            },
            commitFeedback(){
                insertUserSugges(this.feedbackForm).then(res=>{
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.$router.push("/feedbackResult")
                        }
                    }
                })

            }
        }

    }
</script>

<style scoped lang="less">
    .feedbackWarp{
        background: #F7F8F9;
        min-height: 100%;
        .lintgray{
            height: 10px;

            background: #F7F8F9;
        }

    }
    .title{
        background: #ffffff;
        border-bottom: 1px solid #eeeeee;
        padding: 20px 15px;
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;

    }
    .imgUpload{
        background: #ffffff;
        padding-bottom: 20px;
    }
    .imgTitleDiv{
        padding: 16px 15px 16px 15px ;
        background: #fff;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .imgDiv{
        padding: 0 15px;
    }
    .lineTitle{
        border-left: 2px solid #3F7C53;
        height: 17px;
        line-height: 17px;
        padding-left: 6px;
        font-size: 17px;
        font-weight: bold;
    }
    .feedbackInput{

        box-shadow: 0px 1px 0px 0px #EEEEEE, 0px -1px 0px 0px #EEEEEE;

    }

</style>
<style>
    .feedbackWarp .van-field__control{
        font-weight: 400;
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        min-height: 184px!important;
    }
    .feedbackWarp .van-uploader__preview{
        margin-right: 16px!important;
    }

</style>
